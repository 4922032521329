import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Auth } from "aws-amplify";
import {isNullUndefined,isEmptyString, isEmailValid } from '../util';
import Editor from '../components/Editor';
import queryString from 'query-string';
import { navigate } from 'gatsby'
import styles from "../components/landingPage.module.css"
import { Link} from "gatsby"
import CircularProgress from "@material-ui/core/CircularProgress"
import { ToastContainer, toast } from 'react-toastify';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: '',
        password: '',
        confirmPassword: '',
        submitPressed: false,
        validationPassed: false,
        wasValidated: false,
        resetCode: null,
        shouldEmailBeDisabled: true,
        shouldCodeBeDisabled: true,
        resetError: null
      };
  }

  componentDidMount(){
    const {code, email} = queryString.parse(this.props.location.search || "");
    if(!isNullUndefined(code) && !isNullUndefined(email)){
      if(!isEmptyString(code)){
        this.setState({resetCode: code})
      }else{
        this.setState({shouldCodeBeDisabled: false})
      }
      if(!isEmptyString(email)){
        if(!isEmailValid(email)){
          this.setState({shouldEmailBeDisabled: false})
          return
        }
        this.setState({email: email})
      }else{
        this.setState({shouldEmailBeDisabled: false})
      }
    }else{
      this.setState({shouldCodeBeDisabled: false, shouldEmailBeDisabled: false})
        // navigate("/")
    }

  }
  
  handleChange = ({target: {name, value}}) => {
    this.setState({[name]: value, resetError: null})
  }

  resetPassword = async (e) => {
    e.preventDefault();

    if(this.state.submitPressed) return

    this.setState({submitPressed: true, wasValidated: true})

    let {email, password, resetCode, confirmPassword, submitPressed} = this.state;

    if(!isEmailValid(email)){
      this.setState({submitPressed: false, resetError: {message: "Your email is invalid"}})
      return;
    }

    if(!resetCode){
      this.setState({submitPressed: false, resetError: {message: "Please provide a valid reset code"}})
      return;
    }

    if(password.length < 6){
      this.setState({submitPressed: false, resetError: {message: "Your password is too short"}})
      return;
    }

    if(password !== confirmPassword){
      this.setState({submitPressed: false, resetError: {message: "Your passwords do not match"}})
      return;
    }

    await Auth.forgotPasswordSubmit(email, resetCode, password)
    .then(data => {
      toast.success("Your password was updated successfully");

      this.setState({submitPressed: false, resetError: null})
      
      const {goTo} = queryString.parse(this.props.location.search || "");

      setTimeout(() => {
        if(isNullUndefined(goTo) || isEmptyString(goTo)){
          navigate("/");
        }else{
          navigate(goTo);
        }
      }, 2000)

      return;
    })
    .catch(err => {
      this.setState({submitPressed: false, resetError: err})
      return;
    })
  };

  render() {
    const {email, resetCode, password,confirmPassword, wasValidated, submitPressed, shouldEmailBeDisabled, shouldCodeBeDisabled, resetError} = this.state;
    return (
        <div>
          <SEO title={"Reset Your Password"} />
          <ToastContainer
                position="top-right"
                autoClose={3500}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
          <div className="col-8 offset-2">
            <div className="row">
              <div className="col-6 ">
                <div className={styles.landing_logo}>
                  <div className="">
                    <span className={styles.logo}></span>
                  </div>
                </div>
              </div>
              <div className="col-6">
              <div className="container">
                <Link to="/" className="float-right pt-5"> Home</Link>
                </div>
              </div>
            </div>
            
            <div>
            
            </div>
            <SEO title="Reset Your Password" />
            <div style={{minHeight: 'calc(100vh - 291px)'}}>
            <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                      <div className="col-12 pt-4">
                        <h4>Reset Your Password</h4>
                      </div>
                        <div className="col-12">

                            <Editor type={"text"} name={"email"} label={"Email Address"} placeholder={shouldEmailBeDisabled ? email : "Email"}  value={email} required={true} onChange={this.handleChange} variant={"outlined"} error={wasValidated && !isEmailValid(email)} />
                        </div>
                        <div className="col-12">

                            <Editor type={"text"} name={"resetCode"} label={"Reset Code"} placeholder={shouldCodeBeDisabled ? email : "Reset Code"}  value={resetCode} required={true} onChange={this.handleChange} variant={"outlined"} error={wasValidated && isNullUndefined(resetCode)} />
                        </div>
                        <div className="col-12">

                            <Editor type={"text"} name={"password"} label={"New Password"} value={password} required={true} onChange={this.handleChange} variant={"outlined"} error={wasValidated && password.length < 6} />
                        </div>
                        <div className="col-12">
                            <Editor type={"text"} name={"confirmPassword"} label={"Confirm Password"} value={confirmPassword} required={true} onChange={this.handleChange} variant={"outlined"} error={wasValidated && (confirmPassword.length < 6 || password !== confirmPassword)} />
                        </div>

                        {!isNullUndefined(resetError) && <div className="col-12">
                            <small className="text-danger">
                                {resetError.message}
                            </small>
                        </div>}

                        <div className="col-12">
                            <span
                                onClick={this.resetPassword}
                                className="btn"
                                style={{cursor: "pointer"}}>
                                {submitPressed ? <CircularProgress size={20} /> : "Reset Password"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
    );
  }
}

export default ResetPasswordPage;